import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'react-emotion'
import logoCruisio from '../assets/logo__cruisio.png'

function showLogo() {
  if (this.props.path == '/') {
    return (
      <div>this is the home page</div>
    )
  }
  else {
    return (
      <div>this is not the home page</div>
    )
  }
}

const HeroVideoContainer = styled('div')`
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;
`

const HeroVideo = styled('div')`
  color: rgba(2552, 255, 255, .85);
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;

  video {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @media screen and (max-aspect-ratio: 1920/1080) {
      height: 100%;
    }

    @media screen and (min-aspect-ratio: 1920/1080) {
      width: 100%;
    }
  }

  .overlay {
    background: radial-gradient(rgba(0, 0, 0, .5) 10%, rgba(0, 0, 0, 0) 65%);
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const HeroDetails = styled('div')`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 10px;
  margin-bottom: 40px;
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100%;
  text-align: center;
`

const HeroHead = styled('div')`
  align-self: end;
  font-family: "Montserrat";
  font-size: 36px;
  font-weight: 600;
  text-transform: uppercase;
`

const HeroSubhead = styled('div')`
  color: #fff;
  display: grid;
  font-family: "Montserrat";
  font-size: 18px;
`

const HeroActions = styled('div')`
  color: rgba(255, 255, 255, 0.5);
  display: grid;
  align-self: end;
  justify-items: center;
  padding-bottom: 20px;

  polyline {
    stroke: rgba(255, 255, 255, 0.5);
  }
`


export default props => (
  <HeroVideoContainer>
    <HeroVideo>
      <video muted autoPlay loop playsInline poster={props.poster}>
        <source src={props.cover} type="video/mp4" />
      </video>

      <div className="overlay"></div>

      <HeroDetails>
        <HeroHead>
          {props.headText}
        </HeroHead>
        <HeroSubhead>
          {props.subheadText}
          <HeroActions>
            scroll
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
              <polyline fill="none" stroke="#FFF" strokeWidth="3" points="24.728 2.728 24.728 20.728 6.728 20.728" transform="rotate(45 15.728 11.728)"/>
            </svg>
          </HeroActions>
        </HeroSubhead>
      </HeroDetails>
    </HeroVideo>
  </HeroVideoContainer>
)
