import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
// import Hero from '../components/Hero'
import HeroVideo from '../components/HeroVideo'
import Main from '../components/Main'
import styled from 'react-emotion'
import Button from '../components/Button'

import videoPartners from '../assets/partners-compressed.mp4'

import Layout from '../components/layout'
import { rhythm } from '../utils/typography'

const PanelBody = styled.div`
  margin: 0 auto;
  max-width: 600px;
`

const Panel = styled('div')`
  padding: 80px 0;
  &.alt {
    background-color: #F5F8FC;
  }

  & h1 {
    // padding-bottom: 40px;
  }

  & p {
    margin-bottom: 40px;
  }

  label {
    display: block;
    font-size: .875rem;
    margin: 0 auto;
    margin-bottom: 2rem;
    text-align: left;
    text-transform: uppercase;
    width: 100%;

    input {
      font-size: 1.5rem;
      padding: 0.5rem 1rem;
      text-transform: none;
      width: 100%;
    }

    &.bot-field {
      opacity: 0;
      display: none;
    }
  }

  button {
    background: #216EDC;
    border: none;
    border-radius: 3px;
    color: #fff;
    font-weight: 600;
    padding: 1rem 1rem;
    text-transform: uppercase;
    width: 100%;
  }
`

export default () => (
  <Layout>
    <HeroVideo
      cover={videoPartners}
      headText="Test Form"
      subheadText="test"></HeroVideo>
    <Main>
      <Panel>
        <PanelBody>
          <h1>Test Form</h1>
          <p>test test test</p>
        </PanelBody>
      </Panel>
      <Panel className="alt">
        <PanelBody>

          <form name="contact-test" method="post" action="/thankyou" data-netlify="true" data-netlify-honeypot="bot-field">
            <input type="hidden" name="form-name" value="contact-test" />
            <p>
              <label>Your Name: <input type="text" name="name" /></label>
            </p>
            <p>
              <label>Your Email: <input type="email" name="email" /></label>
            </p>
            <p>
              <label>Your Role: <select name="role[]" multiple>
                <option value="leader">Leader</option>
                <option value="follower">Follower</option>
              </select></label>
            </p>
            <p>
              <label>Message: <textarea name="message"></textarea></label>
            </p>
            <p>
              <button type="submit">Send</button>
            </p>
          </form>
        </PanelBody>
      </Panel>
    </Main>
  </Layout>
)
